import React, { Fragment } from 'react';

import { ModalContext } from './ModalProvider';

type Props = {
  children: React.ReactElement;
  id: string;
  activeModal: string;
  additionalModalProps: object;
};

type ContextConsumerProps = {
  children: React.ReactElement;
  id: string;
};

function ModalActivityListener({ children, id, activeModal, additionalModalProps }: Props) {
  return activeModal === id ? (
    <Fragment>
      {React.Children.map(children, (child) =>
        React.cloneElement(child, {
          ...additionalModalProps,
        }),
      )}
    </Fragment>
  ) : null;
}

export default (props: ContextConsumerProps) => (
  <ModalContext.Consumer>
    {({ activeModal, additionalModalProps }) => (
      <ModalActivityListener {...props} activeModal={activeModal} additionalModalProps={additionalModalProps} />
    )}
  </ModalContext.Consumer>
);
