import { useContext } from 'react';

import { Features } from '~/data/.types/api/generated/Account';
import { getAccountPortal } from '~/data/account/helpers/getAccountPortal';
import { useAccount } from '~/data/account/hooks/useAccount';

import { useAccountHasFeature } from '~/helpers/hooks/useAccountHasFeature';
import { useHydrated } from '~/helpers/hooks/useHydrated';
import { openIntercom } from '~/helpers/intercom/openIntercom';

import { ModalContext } from '~/components/.shared/modal/ModalProvider';
import { CONTACT_SUPPORT_MODAL_ID } from '~/components/contact-support/modal/ContactSupportModal';

export function HeaderText() {
  const account = useAccount();
  const accountPortal = getAccountPortal(account?.data, account?.included);
  const hydrated = useHydrated();
  const hasContactSupport = useAccountHasFeature(Features.ReleaseContactSupport);
  const { showModal } = useContext(ModalContext);

  if (hasContactSupport) {
    return (
      <p className="mb-4">
        Share your ideas and suggestions directly with our product team.{' '}
        {!accountPortal && (
          <>
            For general issues and concerns, please{' '}
            <button className="text-blue-primary hover:underline" onClick={() => showModal(CONTACT_SUPPORT_MODAL_ID)}>
              contact support
            </button>
            .
          </>
        )}
      </p>
    );
  }

  return (
    <p className="mb-4">
      Share your ideas and suggestions directly with our product team. For general issues and concerns, please{' '}
      {hydrated && !accountPortal ? (
        <button className="text-blue-primary hover:underline" onClick={() => openIntercom()}>
          contact support
        </button>
      ) : (
        <a href="mailto:contentworkflow@bynder.com">contact support</a>
      )}
      .
    </p>
  );
}
