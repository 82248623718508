import { CrossIcon, LoaderIcon } from '@gathercontent/icons';
import { Avatar, ButtonIcon, ButtonPrimary, ButtonTertiary, Dialog } from '@gathercontent/ui';
import { Link } from '@remix-run/react';
import { useContext, useEffect, useRef, useState } from 'react';

import { getSrcFromCDN } from '~/helpers/assets/getSrcFromCDN';
import { recordClientSideEvent } from '~/helpers/events/recordClientSideEvent';

import ModalActivityListener from '~/components/.shared/modal/ModalActivityListener';
import { ModalContext } from '~/components/.shared/modal/ModalProvider';

import { SHARE_FEEDBACK_FORM_ID } from '../Form';
import { HeaderText } from './HeaderText';

export const SHARE_FEEDBACK_MODAL_ID = 'share-feedback-modal';
const SHARE_FEEDBACK_IFRAME_NAME = 'share-feedback-iframe';

export function ShareFeedbackModal() {
  const iframeRef = useRef<HTMLIFrameElement>(null);
  const [currentUrl, setCurrentUrl] = useState('');
  const [iframeLoading, setIframeLoading] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [formSuccess, setFormSuccess] = useState(false);
  const { hideModal } = useContext(ModalContext);

  const submitForm = () => {
    if (iframeRef.current) {
      setIsLoading(true);
      const form = iframeRef.current.contentDocument?.querySelector(`#${SHARE_FEEDBACK_FORM_ID}`) as HTMLFormElement;
      form.submit();
    }
  };

  useEffect(() => {
    window.addEventListener('message', (event) => {
      if (event.data.type === 'share-feedback-success') {
        setFormSuccess(true);
        setIsLoading(false);
      }

      if (event.data.type === 'share-feedback-error') {
        setIsLoading(false);
      }
    });

    return () => {
      window.removeEventListener('message', () => {});
    };
  }, []);

  useEffect(() => {
    setCurrentUrl(window.location.href);
    if (!iframeLoading) {
      recordClientSideEvent('Opened feedback modal', {
        currentUrl: window.location.href,
        feedbackTrigger: 'modal',
        linkId: 'header-menu-button',
      });
    }
  }, [iframeLoading]);

  const resetForm = () => {
    setFormSuccess(false);
    setIsLoading(false);
  };

  const hideModalAndReset = () => {
    resetForm();
    hideModal();
    setIframeLoading(true);
  };

  return (
    <ModalActivityListener id={SHARE_FEEDBACK_MODAL_ID}>
      <Dialog open includeBackdrop onOutsideClick={hideModalAndReset} className="w-11/12 lg:w-1/2 2xl:w-1/3 p-4">
        <Dialog.Header>
          <p className="text-neutral-20 text-lg">Suggest a feature</p>
          <ButtonIcon onClick={hideModalAndReset} className="absolute -top-2 -right-2">
            <CrossIcon iconSize="8" />
          </ButtonIcon>
          {!formSuccess && <HeaderText />}
        </Dialog.Header>
        <Dialog.Body className="-mx-4 h-[21rem]">
          {iframeLoading && (
            <div className="w-full h-full flex items-center justify-center">
              <LoaderIcon className="fill-neutral-primary" iconSize="24" />
            </div>
          )}
          <iframe
            onLoad={() => setIframeLoading(false)}
            ref={iframeRef}
            name={SHARE_FEEDBACK_IFRAME_NAME}
            className={`w-full h-full ${iframeLoading ? 'invisible' : ''}`}
            title="Share feedback form"
            src={`/share-feedback?iframe=true&origin=${currentUrl}`}
          />
        </Dialog.Body>
        <Dialog.Footer className="flex items-center justify-between">
          {formSuccess ? (
            <Link
              to={`/share-feedback?iframe=true&origin=${currentUrl}`}
              className="button-base button-tertiary button-base-sm no-underline flex justify-center"
              target={SHARE_FEEDBACK_IFRAME_NAME}
              onClick={resetForm}
            >
              Share more feedback
            </Link>
          ) : (
            <div className="flex items-center">
              <Avatar src={getSrcFromCDN('/images/hugh.png')} alt="" />
              <p className="text-neutral-40 ml-2">
                Prefer to talk?{' '}
                <a
                  target="_blank"
                  href="https://calendar.app.google/A8UEiiDtydNDAwf36"
                  onClick={() =>
                    recordClientSideEvent('Clicked Book a Call link', {
                      feedbackTrigger: 'modal',
                      currentUrl: window.location.href,
                      linkId: 'header-menu-button',
                    })
                  }
                >
                  Book a call <span aria-label="in a new tab">↗︎</span>
                </a>
              </p>
            </div>
          )}
          <div className="flex">
            <ButtonTertiary onClick={hideModalAndReset}>{formSuccess ? 'Close' : 'Cancel'}</ButtonTertiary>
            {!formSuccess && (
              <ButtonPrimary loading={isLoading} disabled={isLoading} onClick={submitForm} className="ml-2">
                Submit
              </ButtonPrimary>
            )}
          </div>
        </Dialog.Footer>
      </Dialog>
    </ModalActivityListener>
  );
}
