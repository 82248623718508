import type { ResourceObject } from 'ts-json-api';

import type { AccountApiSchema } from '~/data/.types/api/generated/Account';
import { ResourceTypes } from '~/data/.types/apiTypes';
import type { DamPortalResource } from '~/data/dam/.types/damTypes';

export function getAccountPortal(account: AccountApiSchema | undefined, included: ResourceObject[] | undefined) {
  if (!account && !included) {
    return null;
  }

  const portalId = account?.relationships?.damPortal?.data?.id;

  return included?.filter(
    (item) => item.type === ResourceTypes.DamPortal && portalId === item.id,
  )[0] as DamPortalResource;
}
