import type { Dispatch, ReactNode, SetStateAction } from 'react';
import React, { useEffect, useState } from 'react';

export interface Context<T = object> {
  showModal: (id: string) => void;
  hideModal: () => void;
  setAdditionalModalProps: Dispatch<SetStateAction<object>>;
  activeModal: string;
  additionalModalProps: T;
}

export const ModalContext = React.createContext<Context>({
  showModal: () => {},
  hideModal: () => {},
  setAdditionalModalProps: () => {},
  activeModal: '',
  additionalModalProps: {},
});

declare global {
  interface Window {
    hideModal?: () => void;
  }
}

interface Props {
  defaultActiveModal?: string;
  children: ReactNode;
}

export function ModalProvider({ defaultActiveModal = '', children }: Props) {
  const [activeModal, setActiveModal] = useState(defaultActiveModal);
  const [additionalModalProps, setAdditionalModalProps] = useState({});

  const showModal = (id: string) => setActiveModal(id);

  const hideModal = () => {
    setActiveModal('');
    setAdditionalModalProps({});
  };

  useEffect(() => {
    window.hideModal = hideModal;
    return () => {
      delete window.hideModal;
    };
  }, []);

  const sharedState = {
    showModal,
    hideModal,
    setAdditionalModalProps,
    activeModal,
    additionalModalProps,
  };

  return <ModalContext.Provider value={sharedState}>{children}</ModalContext.Provider>;
}
