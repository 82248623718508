import { CrossIcon, LoaderIcon } from '@gathercontent/icons';
import { ButtonIcon, ButtonPrimary, ButtonTertiary, Dialog } from '@gathercontent/ui';
import { Link } from '@remix-run/react';
import { useContext, useEffect, useRef, useState } from 'react';

import { recordClientSideEvent } from '~/helpers/events/recordClientSideEvent';

import ModalActivityListener from '~/components/.shared/modal/ModalActivityListener';
import { ModalContext } from '~/components/.shared/modal/ModalProvider';
import { SHARE_FEEDBACK_MODAL_ID } from '~/components/share-feedback/modal/ShareFeedbackModal';

import { CONTACT_SUPPORT_FORM_ID } from '../Form';

export const CONTACT_SUPPORT_MODAL_ID = 'contact-support-modal';
const CONTACT_SUPPORT_IFRAME_NAME = 'contact-support-iframe';

export function ContactSupportModal() {
  const iframeRef = useRef<HTMLIFrameElement>(null);
  const [currentUrl, setCurrentUrl] = useState('');
  const [iframeLoading, setIframeLoading] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [formSuccess, setFormSuccess] = useState(false);
  const { hideModal, showModal } = useContext(ModalContext);

  const submitForm = () => {
    if (iframeRef.current) {
      setIsLoading(true);
      const iframeDocument = iframeRef.current.contentDocument;
      if (iframeDocument) {
        const form = iframeDocument.querySelector(`#${CONTACT_SUPPORT_FORM_ID}`) as HTMLFormElement;
        form.submit();
      }
    }
  };

  useEffect(() => {
    window.addEventListener('message', (event) => {
      if (event.data.type === 'contact-support-success') {
        setFormSuccess(true);
        setIsLoading(false);
      }

      if (event.data.type === 'contact-support-error') {
        setIsLoading(false);
      }
    });

    return () => {
      window.removeEventListener('message', () => {});
    };
  }, []);

  useEffect(() => {
    setCurrentUrl(window.location.href);
    if (!iframeLoading) {
      recordClientSideEvent('Clicked Open a Support ticket link', {
        trigger: 'modal',
      });
    }
  }, [iframeLoading]);

  const resetForm = () => {
    setFormSuccess(false);
    setIsLoading(false);
  };

  const hideModalAndReset = () => {
    resetForm();
    hideModal();
    setIframeLoading(true);
  };

  return (
    <ModalActivityListener id={CONTACT_SUPPORT_MODAL_ID}>
      <Dialog open includeBackdrop onOutsideClick={hideModalAndReset} className="w-11/12 lg:w-1/2 2xl:w-1/3 p-4">
        <Dialog.Header>
          <p className="text-neutral-20 text-lg">Contact support</p>
          <ButtonIcon onClick={hideModalAndReset} className="absolute -top-2 -right-2">
            <CrossIcon iconSize="8" />
          </ButtonIcon>
          {!formSuccess && (
            <p className="mt-4">
              Have a question or need help with something? Our support team are here to help. Have an idea for a
              feature?{' '}
              <button className="text-blue-primary hover:underline" onClick={() => showModal(SHARE_FEEDBACK_MODAL_ID)}>
                Suggest a feature
              </button>
            </p>
          )}
        </Dialog.Header>
        <Dialog.Body className="-mx-4 h-[25rem] 2xs:h-[20rem]">
          {iframeLoading && (
            <div className="w-full h-full flex items-center justify-center">
              <LoaderIcon className="fill-neutral-primary" iconSize="24" />
            </div>
          )}
          <iframe
            onLoad={() => setIframeLoading(false)}
            ref={iframeRef}
            name={CONTACT_SUPPORT_IFRAME_NAME}
            className={`w-full h-full ${iframeLoading ? 'invisible' : ''}`}
            title="Contact support form"
            src={`/contact-support?iframe=true&origin=${currentUrl}`}
          />
        </Dialog.Body>
        <Dialog.Footer className="flex items-center justify-end">
          <Link
            target="_blank"
            className="mr-auto"
            to="https://support.bynder.com/hc/en-us/sections/15693340932242-Content-Workflow"
          >
            Visit the knowledge base <span aria-label="in a new tab">↗︎</span>
          </Link>
          <ButtonTertiary onClick={hideModalAndReset}>{formSuccess ? 'Close' : 'Cancel'}</ButtonTertiary>
          {!formSuccess && (
            <ButtonPrimary loading={isLoading} disabled={isLoading} onClick={submitForm} className="ml-2">
              Submit
            </ButtonPrimary>
          )}
        </Dialog.Footer>
      </Dialog>
    </ModalActivityListener>
  );
}
